import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

const Ck = ({ editorData, setEditorData }) => {

    const handleChange = (event, editor) => {
        const data = editor.getData();
        setEditorData(data);
    };
    return (
        <div>
            <CKEditor

                editor={InlineEditor}
                data={editorData || ''}
                onChange={handleChange}
                config={
                    { placeholder: 'Enter the content  here...' }
                }
            />
            <div>
                {/* <div
                    dangerouslySetInnerHTML={{ __html: editorData || '' }}
                /> */}
            </div>
        </div>
    );
};

export default Ck;

// src/components/ProtectedRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const ProtectedRoute = ({ element }) => {
    const { isAuthenticated } = useContext(AuthContext);
    console.log('Is authenticated in ProtectedRoute:', isAuthenticated);
    return isAuthenticated ? element : <Navigate to="/" />;
};

export default ProtectedRoute;

import axiosInstance from './axiosInstance';

const api_url = process.env.REACT_APP_CHANGE_PASSWORD_API;
// Function to change password
export const changePassword = async (currentPassword, newPassword) => {
    try {
        const token = localStorage.getItem('authToken');

        // Make the API request
        const response = await axiosInstance.post(api_url, {
            currentPassword,
            newPassword,

        },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

        return response.data;

    } catch (error) {

        if (error.response && error.response.status === 400) {
            console.error('Invalid request. Please check your input.');
        } else {
            console.error('An error occurred. Please try again.');
        }
        // Optionally rethrow the error if needed
        throw error;
    }
};

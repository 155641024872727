import React, { useState, useContext, } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../api/loginApi';
import { showErrorToast, showSuccessToast } from '../api/ToastNotification';
import { AuthContext } from '../context/AuthContext';


const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({ email: '', password: '' });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [isPasswordShown, setIsPasswordShown] = useState(false);

    const { login: contextLogin } = useContext(AuthContext);

    const validate = () => {
        let isValid = true;
        const newErrors = { email: '', password: '' };

        if (!email) {
            newErrors.email = 'Email is required';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Email is invalid';
            isValid = false;
        }

        if (!password) {
            newErrors.password = 'Password is required';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validate()) {
            setLoading(true);
            try {
                const result = await login({ email, password });
                localStorage.setItem('authToken', result.data.token);
                contextLogin(result.data.token);

                setEmail('');
                setPassword('');
                setErrors({ email: '', password: '' });
                await showSuccessToast('Login successful!');
                navigate('/admin-dashboard');
            } catch (error) {
                console.error('Login failed', error.response ? error.response.data : error.message);
                showErrorToast('Login failed. Please check your credentials and try again.');
            } finally {
                setLoading(false);
            }
        } else {
            showErrorToast('Please fix the errors in the form.');
        }
    };

    const togglePasswordVisibility = () => {
        setIsPasswordShown(!isPasswordShown);
    };

    return (
        <div className='admin-login'>
            <section className="login-section">
                <div className="container-fluid g-0">
                    <div className="row g-0">
                        <div className="col-md-7">
                            <div className="login-main-blk">
                                <div className="login-logo">
                                    <a href="dashboard.html">
                                        <img src="/assets/images/statzo-logo.svg" alt="logo" />
                                    </a>
                                </div>
                                <div className="login-form-blk">
                                    <h1>Welcome back!</h1>
                                    <p>Login to access all your data</p>
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                placeholder="Enter your email address"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            {errors.email && <div className="error-message">{errors.email}</div>}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password">Password</label>
                                            <div className="p-relative">
                                                <input
                                                    type={isPasswordShown ? "text" : "password"}
                                                    className="form-control"
                                                    id="password"
                                                    placeholder="Enter your password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                                <span id="eye" onClick={togglePasswordVisibility}>
                                                    {isPasswordShown ? (
                                                        <i className="fa-solid fa-eye" id="eyeIcon"></i>
                                                    ) : (
                                                        <i className="fa-solid fa-eye-slash" id="eyeIcon"></i>
                                                    )}
                                                </span>
                                            </div>
                                            {errors.password && <div className="error-message">{errors.password}</div>}
                                        </div>
                                        <div className="login-btn-blk">
                                            <button type="submit" className="btn primary-btn" disabled={loading}>
                                                {loading ? 'Logging in...' : 'Login'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 d-none d-md-block">
                            <div className="login-img-blk">
                                <div className="img-blk">
                                    <img src="/assets/images/login-bg.png" alt="Banner" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    );
}

export default Login;

// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { login } from '../api/loginApi';
// import { showErrorToast, showSuccessToast } from './ToastNotification';

// const Login = () => {
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [errors, setErrors] = useState({ email: '', password: '' });
//     const [loading, setLoading] = useState(false);
//     const navigate = useNavigate();
//     const [isPasswordShown, setIsPasswordShown] = useState(false);

//     const validate = () => {
//         let isValid = true;
//         const newErrors = { email: '', password: '' };

//         if (!email) {
//             newErrors.email = 'Email is required';
//             isValid = false;
//         } else if (!/\S+@\S+\.\S+/.test(email)) {
//             newErrors.email = 'Email is invalid';
//             isValid = false;
//         }

//         if (!password) {
//             newErrors.password = 'Password is required';
//             isValid = false;
//         }

//         setErrors(newErrors);
//         return isValid;
//     };

//     const handleSubmit = async (event) => {
//         event.preventDefault();
//         if (validate()) {
//             setLoading(true);
//             try {
//                 const result = await login({ email, password });
//                 localStorage.setItem('authToken', result.data.token);

//                 setEmail('');
//                 setPassword('');
//                 setErrors({ email: '', password: '' });
//                 showSuccessToast('Login successful!');
//                 navigate('/admin-dashboard');

//             } catch (error) {
//                 console.error('Login failed', error.response ? error.response.data : error.message);
//                 showErrorToast('Login failed. Please check your credentials and try again.');
//             } finally {
//                 setLoading(false);
//             }
//         }
//     };

//     const togglePasswordVisibility = () => {
//         setIsPasswordShown(!isPasswordShown);
//     };

//     return (
//         <div className='admin-login'>
//             <section className="login-section">
//                 <div className="container-fluid g-0">
//                     <div className="row g-0">
//                         <div className="col-md-7">
//                             <div className="login-main-blk">
//                                 <div className="login-logo">
//                                     <a href="dashboard.html">
//                                         <img src="/assets/images/statzo-logo.svg" alt="logo" />
//                                     </a>
//                                 </div>
//                                 <div className="login-form-blk">
//                                     <h1>Welcome back!</h1>
//                                     <p>Login to access all your data</p>
//                                     <form onSubmit={handleSubmit}>
//                                         <div className="form-group">
//                                             <label htmlFor="email">Email</label>
//                                             <input
//                                                 type="email"
//                                                 className="form-control"
//                                                 id="email"
//                                                 placeholder="Enter your email address"
//                                                 value={email}
//                                                 onChange={(e) => setEmail(e.target.value)}
//                                             />
//                                             {errors.email && <div className="error-message">{errors.email}</div>}
//                                         </div>
//                                         <div className="form-group">
//                                             <label htmlFor="password">Password</label>
//                                             <div className="p-relative">
//                                                 <input
//                                                     type={isPasswordShown ? "text" : "password"}
//                                                     className="form-control"
//                                                     id="password"
//                                                     placeholder="Enter your password"
//                                                     value={password}
//                                                     onChange={(e) => setPassword(e.target.value)}
//                                                 />
//                                                 <span id="eye" onClick={togglePasswordVisibility}>
//                                                     {isPasswordShown ? (
//                                                         <i className="fa-solid fa-eye" id="eyeIcon"></i>
//                                                     ) : (
//                                                         <i className="fa-solid fa-eye-slash" id="eyeIcon"></i>
//                                                     )}
//                                                 </span>
//                                             </div>
//                                             {errors.password && <div className="error-message">{errors.password}</div>}
//                                         </div>
//                                         <div className="login-btn-blk">
//                                             <button type="submit" className="btn primary-btn" disabled={loading}>
//                                                 {loading ? 'Logging in...' : 'Login'}
//                                             </button>
//                                         </div>
//                                     </form>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-md-5 d-none d-md-block">
//                             <div className="login-img-blk">
//                                 <div className="img-blk">
//                                     <img src="/assets/images/login-bg.png" alt="Banner" />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </div>
//     );
// }

// export default Login;





export const updateProfile = async (name, email, image) => {
    // const api_url = 'http://192.168.10.234:3026/admin/api/profile/update'
    const api_url = process.env.REACT_APP_UPDATE_PROFILE_API;
    const token = localStorage.getItem('authToken')
    const response = await fetch(api_url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            name,
            email,
            image
        })
    });

    if (!response.ok) {
        throw new Error('Failed to update profile');
    }

    return response.json();
};
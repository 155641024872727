import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const DateRangePicker = ({ dateFrom, dateTo, handleDateChange }) => {
    // Create refs for both DatePickers
    const dateFromRef = useRef(null);
    const dateToRef = useRef(null);

    return (
        <div className="filter-date-blk">
            <div className="form-block p-relative" onClick={() => dateFromRef.current.setFocus()}>
                <span className="cal-icon" />
                <DatePicker
                    selected={dateFrom}
                    onChange={(date) => handleDateChange(date, 'from')}
                    placeholderText="From"
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                    ref={dateFromRef}
                />
            </div>
            <div className="form-block p-relative" onClick={() => dateToRef.current.setFocus()}>
                <span className="cal-icon" />
                <DatePicker
                    selected={dateTo}
                    onChange={(date) => handleDateChange(date, 'to')}
                    placeholderText="To"
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                    ref={dateToRef}
                />
            </div>
        </div>
    );
};

export default DateRangePicker;

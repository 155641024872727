// src/components/ToastNotification.js
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for toast notifications

const ToastNotification = () => {
    return (
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
    );
};

export const showSuccessToast = (message) => {
    // Replace with your actual implementation
    return new Promise((resolve) => {
        // Show the success toast
        toast.success(message);
        setTimeout(resolve, 3000);
    });
};

export const showErrorToast = (message) => {
    toast.error(message);
};

export default ToastNotification;


import React from 'react';
import DateRangePicker from './DateRangePicker';
import SearchInput from './SearchInput';

const FilterForm = ({ dateFrom, dateTo, handleDateChange, searchQuery, handleSearchChange, handleClear }) => {
    return (
        <form>
            <div className="row justify-content-between g-3">
                <div className="col-xl-12">
                    <div className="sub-heading mb-2 mt-0 p-relative">
                        <h2 className="h4 mb-0">Sort By:</h2>
                    </div>
                </div>
                <div className="col-xl-12">
                    <div className="filter-wrapper">
                        <DateRangePicker
                            dateFrom={dateFrom}
                            dateTo={dateTo}
                            handleDateChange={handleDateChange}
                        />
                        <div className="filter-search-btn-blk">
                            <SearchInput
                                searchQuery={searchQuery}
                                handleSearchChange={handleSearchChange}
                            />
                            <div className="filter-action-btn-blk">
                                <button
                                    type="button"
                                    className="btn secondary-btn"
                                    onClick={handleClear}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default FilterForm;

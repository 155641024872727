// src/App.js
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { ProfileImageProvider } from './context/ProfileImageContext';
import { AuthProvider } from './context/AuthContext';
import { ProfileNameProvider } from './context/ProfileNameContext';
import ToastNotification from './api/ToastNotification';


const App = () => {
  return (

    <ProfileImageProvider>
      <ToastNotification />
      <ProfileNameProvider>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </ProfileNameProvider>
    </ProfileImageProvider >

  );
};

export default App;
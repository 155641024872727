import React, { useState, useEffect } from 'react';
import axiosInstance from '../api/axiosInstance';
import Ck from '../components/Ck';
// import QuillEditor from '../common/Editor';
import { showErrorToast, showSuccessToast } from '../api/ToastNotification';

const ContentManagement = () => {

    function decodeHtml(html) {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    }
    const [termsContent, setTermsContent] = useState('');

    const [privacyContent, setPrivacyContent] = useState('');

    const [aboutContent, setAboutContent] = useState('');

    useEffect(() => {
        const fetchContent = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const termsResponse = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_CONETNT_DISPLAY_ENDPOINT}`, {
                    params: { type: 'Terms' },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });


                setTermsContent(decodeHtml(termsResponse.data.data.description || ''));

                const privacyResponse = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_CONETNT_DISPLAY_ENDPOINT}`, {
                    params: { type: 'Privacy' },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });

                setPrivacyContent(decodeHtml(privacyResponse.data.data.description || ''));
            } catch (error) {
                console.error('Error fetching content:', error);
            }
        };

        fetchContent();
    }, []);

    const handleTermsSubmit = async () => {
        try {
            const token = localStorage.getItem('authToken');
            await axiosInstance.post(
                `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_CONETNT_POST_ENDPOINT}`,
                {
                    type: 'Terms',
                    description: termsContent,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            showSuccessToast('Terms & Conditions updated successfully!');

            setTermsContent(termsContent);
        } catch (error) {
            console.error('Error submitting Terms & Conditions:', error);
            showErrorToast('Failed to update Terms & Conditions.');
        }
    };

    const handlePrivacySubmit = async () => {
        try {
            const token = localStorage.getItem('authToken');
            await axiosInstance.post(
                `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_CONETNT_POST_ENDPOINT}`,
                {
                    type: 'Privacy',
                    description: privacyContent,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            showSuccessToast('Privacy Policy updated successfully!');

            setPrivacyContent(privacyContent);
        } catch (error) {
            console.error('Error submitting Privacy Policy:', error);
            showErrorToast('Failed to update Privacy Policy.');
        }
    };

    const handleAboutSubmit = async () => {
        try {
            const token = localStorage.getItem('authToken');
            await axiosInstance.post(
                `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_CONETNT_POST_ENDPOINT}`,
                {
                    type: 'about',
                    description: aboutContent,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            alert('About Us updated successfully!');
        } catch (error) {
            console.error('Error submitting About Us:', error);
            alert('Failed to update About Us.');
        }
    };
    return (
        <div>
            <section className="main-section spacer">
                <div className="container">
                    <div className="wrapper-card">
                        <div className="row gy-3">
                            <div className="col-xl-12">

                                <ul className="content-tabs" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link active"
                                            id="pills-terms-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-terms"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-terms"
                                            aria-selected="true"
                                        >
                                            Terms & Conditions
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="pills-privacy-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-privacy"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-privacy"
                                            aria-selected="false"
                                        >
                                            Privacy Policy
                                        </button>
                                    </li>
                                    {/* <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="pills-about-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-about"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-about"
                                            aria-selected="false"
                                        >
                                            About Us
                                        </button>
                                    </li> */}
                                </ul>
                                <div className="tab-content pt-4" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-terms" role="tabpanel" aria-labelledby="pills-terms-tab" tabIndex={0}>
                                        <div className="tab-content-wrapper">
                                            <h2>Terms & Conditions Description</h2>

                                            {/* <div className='content-data'>
                                                <div dangerouslySetInnerHTML={{ __html: displayTermsContent }}></div>
                                            </div> */}
                                            <Ck editorData={termsContent} setEditorData={setTermsContent} />
                                            <div className='content-buttons'>
                                                <button type="button" className='btn primary-btn' onClick={handleTermsSubmit}>Submit Terms & Conditions</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-privacy" role="tabpanel" aria-labelledby="pills-privacy-tab" tabIndex={0}>
                                        <div className="tab-content-wrapper">
                                            <h2>Privacy Policy Description</h2>
                                            {/* <div className='content-data'>
                                                <div dangerouslySetInnerHTML={{ __html: displayPrivacyContent }} />
                                            </div> */}
                                            <Ck editorData={privacyContent} setEditorData={setPrivacyContent} />
                                            <div className='content-buttons'>
                                                <button type="button" className='btn primary-btn' onClick={handlePrivacySubmit}>Submit Privacy Policy</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-about" role="tabpanel" aria-labelledby="pills-about-tab" tabIndex={0}>
                                        <div className="tab-content-wrapper">
                                            <h2>About Us</h2>
                                            {/* <Ck editorData={aboutContent} setEditorData={setAboutContent} /> */}
                                            <div className='content-buttons'>
                                                <button type="button" className='btn primary-btn' onClick={handleAboutSubmit}>Submit About Us</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ContentManagement;

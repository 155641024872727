import axiosInstance from './axiosInstance';

export const fetchUserProfile = async () => {
    try {
        const token = localStorage.getItem('authToken');

        if (!token) {
            throw new Error('No authentication token found.');
        }

        const endpoint = process.env.REACT_APP_USER_PROFILE_API;

        const response = await axiosInstance.get(endpoint, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });


        const data = response.data;

        return {
            name: data.data.name,
            email: data.data.email,
            image: data.data.image,
        };
    } catch (error) {
        console.error('Error fetching user profile:', error.message);
        throw error;
    }
};

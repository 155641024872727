import React, { createContext, useState, useContext } from 'react';

const ProfileNameContext = createContext();

export const ProfileNameProvider = ({ children }) => {
    const [name, setName] = useState('');

    return (
        <ProfileNameContext.Provider value={{ name, setName }}>
            {children}
        </ProfileNameContext.Provider>
    );
};

export const useProfileNameContext = () => useContext(ProfileNameContext);

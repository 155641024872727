import React, { createContext, useEffect, useState, useContext } from 'react';
import { fetchUserProfile } from '../api/fetchUserProfile';

const ProfileImageContext = createContext();

export const ProfileImageProvider = ({ children }) => {
    const [profileImage, setProfileImage] = useState("");

    useEffect(() => {
        const getUserProfile = async () => {
            try {
                const profileData = await fetchUserProfile();
                if (profileData) {

                    if (profileData.image) {

                        setProfileImage(`${process.env.REACT_APP_BACKEND_URL}/public/${profileData.image}`);
                    }
                    else {
                        setProfileImage('/assets/images/user.png');
                    }

                }

            } catch (error) {
                console.error('Error fetching user profile', error);
            }
        };

        getUserProfile();


    }, [setProfileImage]);

    return (
        <ProfileImageContext.Provider value={{ profileImage, setProfileImage }}>
            {children}
        </ProfileImageContext.Provider>
    );
};

export const useProfileImage = () => useContext(ProfileImageContext);


import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';


const Layout = () => {
    return (

        <div className='layout'>
            <Sidebar />
            <div className="main-wrapper">

                <Header />

                <div className="content-wrapper">
                    <Outlet />
                </div>
            </div>
        </div>

    );
};

export default Layout;

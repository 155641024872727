import React, { useState, useEffect } from 'react';
import { changePassword } from '../api/changePassword';
import { updateProfile } from '../api/updateProfile';
import { fetchUserProfile } from '../api/fetchUserProfile';
import { useProfileImage } from '../context/ProfileImageContext';
import { showErrorToast, showSuccessToast } from '../api/ToastNotification';


const Profile = () => {
    const { profileImage, setProfileImage } = useProfileImage();

    const [isProfileImageChange, setProfileImageChange] = useState(false);

    const [isCurrentPasswordShown, setIsCurrentPasswordShown] = useState(false);

    const [isNewPasswordShown, setIsNewPasswordShown] = useState(false);

    const [isConfirmPasswordShown, setIsConfirmPasswordShown] = useState(false);

    const [currentPassword, setCurrentPassword] = useState('');

    const [newPassword, setNewPassword] = useState('');

    const [confirmPassword, setConfirmPassword] = useState('');

    const [fullName, setFullName] = useState('');

    const [email, setEmail] = useState('');

    useEffect(() => {
        const getUserProfile = async () => {
            try {
                const profileData = await fetchUserProfile();

                if (profileData) {
                    setFullName(profileData.name || '');
                    setEmail(profileData.email || '');

                    if (profileData.image) {

                        const url = `${process.env.REACT_APP_BACKEND_URL}/public/${profileData.image}`;
                        // const url = `http://192.168.10.234:3026/public/${profileData.image}`
                        setProfileImage(url);
                        setProfileImageChange(false);
                    }
                    else {
                        setProfileImage('');
                    }

                }

            } catch (error) {
                console.error('Error fetching user profile', error);
            }
        };

        getUserProfile();


    }, [setProfileImage]);



    // Toggle visibility for old password
    const toggleCurrentPasswordVisibility = () => {
        setIsCurrentPasswordShown(prevState => !prevState);
    };

    // Toggle visibility for new password
    const toggleNewPasswordVisibility = () => {
        setIsNewPasswordShown(prevState => !prevState);
    };

    // Toggle visibility for confirm password
    const toggleConfirmPasswordVisibility = () => {
        setIsConfirmPasswordShown(prevState => !prevState);
    };

    const handleChangePassword = async () => {

        if (newPassword !== confirmPassword) {
            showErrorToast('New password and confirm password do not match.');
            return;
        }

        try {
            await changePassword(currentPassword, newPassword);

            // Handle success
            showSuccessToast('Password changed successfully!');
            const modalElement = document.getElementById('changePasswordModal');
            const modal = window.bootstrap.Modal.getInstance(modalElement);
            if (modal) {
                modal.hide();
            } else {
                // If modal instance does not exist, create a new one
                const newModal = new window.bootstrap.Modal(modalElement);
                newModal.hide();
            }

            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');


        } catch (error) {
            // Handle error
            console.error('Error changing password');
            showErrorToast('Failed to change password. Please try again.');
        }
    };
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {

            const reader = new FileReader();

            reader.onloadend = () => {

                setProfileImage(reader.result);
                setProfileImageChange(true);
            };
            reader.readAsDataURL(file);
        }
        else {
            setProfileImage('');
            setProfileImageChange(false);
        }
    };

    const handleSave = async () => {
        try {
            const profileImageResulted = profileImage.split(',')[1];
            const imageToSend = isProfileImageChange ? profileImageResulted : '';
            await updateProfile(fullName, email, imageToSend);
            setProfileImageChange(true)
            showSuccessToast('Profile updated successfully!');
        } catch (error) {
            console.error('Error updating profile', error);
            showErrorToast('Failed to update profile. Please try again.');
        }
    };
    return (
        <>
            <section className="main-section profile-section spacer">
                <div className="container">
                    <div className="row gy-3">
                        <div className="col-xl-10">
                            <form>
                                <div className="row justify-content-start g-3">
                                    <div className="col-xxl-12">
                                        <div className="profile-main-wrapper">
                                            <div className="profile-img">
                                                <img src={profileImage} alt="Profile" />
                                                <div className='upload-profile'>
                                                    <input type="file"
                                                        className='d-none'
                                                        id="uploadProfile"
                                                        accept="image/*"
                                                        onChange={handleImageUpload} />
                                                    <label htmlFor='uploadProfile'>
                                                        <i className="fa-solid fa-upload"></i>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="profile-details">
                                                <div className="row gy-4">
                                                    <div className="col-lg-6">
                                                        <div className="sub-heading mt-0 mb-3">
                                                            <h2 className="h4 mb-0">Full Name</h2>
                                                        </div>
                                                        <div className="form-block p-relative">
                                                            <input type="text" className="form-control" placeholder="Full Name"
                                                                value={fullName}
                                                                onChange={(e) => setFullName(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="sub-heading mt-0 mb-3">
                                                            <h2 className="h4 mb-0">Email Address</h2>
                                                        </div>
                                                        <div className="form-block p-relative">
                                                            <input type="text" className="form-control" placeholder="Email"
                                                                value={email}
                                                                readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="profile-btns">
                                                            <a href="#!" className="btn primary-border-btn" data-bs-toggle="modal" data-bs-target="#changePasswordModal">Change Password</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-6 align-self-end">
                                                        <div className="profile-btns">
                                                            <button type="button" onClick={handleSave} className="btn primary-btn me-0 w-100">Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            {/* Change Password Modal */}
            <div className="modal common-modal fade" id="changePasswordModal" tabIndex={-1} aria-labelledby="changePasswordLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            <div className="common-box-body">
                                <h2>Change Password</h2>

                                <div className="common-modal-form">
                                    <div className="common-modal-field">
                                        <label htmlFor="oldPassword">Old Password</label>
                                        <div className="p-relative">
                                            <input
                                                type={isCurrentPasswordShown ? "text" : "password"}
                                                className="form-control"
                                                id="oldPassword"

                                                value={currentPassword}
                                                onChange={(e) => setCurrentPassword(e.target.value)}
                                            />
                                            <span id="eye" onClick={toggleCurrentPasswordVisibility}>
                                                <i className={`fa - solid ${isCurrentPasswordShown ? 'fa-eye' : 'fa-eye-slash'}`} id="eyeIcon1" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="common-modal-field">
                                        <label htmlFor="newPassword">New Password</label>
                                        <div className="p-relative">
                                            <input
                                                type={isNewPasswordShown ? "text" : "password"}
                                                className="form-control"
                                                id="newPassword"

                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                            />
                                            <span id="eye" onClick={toggleNewPasswordVisibility}>
                                                <i className={`fa - solid ${isNewPasswordShown ? 'fa-eye' : 'fa-eye-slash'}`} id="eyeIcon2" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="common-modal-field">
                                        <label htmlFor="confirmPassword">Confirm Password</label>
                                        <div className="p-relative">
                                            <input
                                                type={isConfirmPasswordShown ? "text" : "password"}
                                                className="form-control"
                                                id="confirmPassword"

                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                            <span id="eye" onClick={toggleConfirmPasswordVisibility}>
                                                <i className={`fa - solid ${isConfirmPasswordShown ? 'fa-eye' : 'fa-eye-slash'}`} id="eyeIcon3" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="common-modal-btns">
                                    <button className="btn primary-btn me-0" onClick={handleChangePassword}>Save</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Profile;

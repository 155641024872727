import React from 'react'

const Pagination = ({ currentPage, totalPages, onPageChange, setRowsPerPage, currentRow }) => {

    const handleRowsPerPageChange = (e) => {
        const newValue = e?.target?.value || '';

        setRowsPerPage(Number(newValue));

    };
    return (
        <div>
            <div className="pagination-controls">
                <div className='select-row'>
                    <label htmlFor="rowsPerPage">Rows per page:</label>
                    <select className='form-select'
                        id="rowsPerPage"
                        value={currentRow}
                        onChange={handleRowsPerPageChange}>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                    </select>
                </div>
                <div className="pagination-buttons">
                    {currentPage === 1 ?
                        <button className='disabled'><i className="fa-solid fa-chevron-left"></i></button> : <button
                            onClick={() => onPageChange(currentPage - 1)}

                        ><i className="fa-solid fa-chevron-left"></i></button>}

                    <span>Page {currentPage} of {totalPages}</span>
                    {totalPages > currentPage ? <button
                        onClick={() => {

                            onPageChange(currentPage + 1)

                        }}

                    ><i className="fa-solid fa-chevron-right"></i></button> : <button className='disabled'><i className="fa-solid fa-chevron-right"></i></button>}
                </div>
            </div >
        </div>
    )
}

export default Pagination
// src/components/Sidebar.js
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import $ from 'jquery';

const Sidebar = () => {
    const location = useLocation();
    useEffect(() => {
        $(".closeBtn").on("click", function () {
            $(".side-navbar").toggleClass("side-hide");
            $(".main-wrapper").toggleClass("main-width");
        });
        return () => {
            $(".closeBtn").off("click");
        };
    }, []);

    const isActive = (path) => location.pathname === path ? 'active' : '';

    return (
        <div className="side-navbar">
            <button className="closeBtn">
                <i className="fa-solid fa-times"></i>
            </button>
            <ul className="nav flex-column position-relative">
                <li className="nav-item sidebar-logo">
                    <a className="navbar-brand text-white" href="#">
                        <img src="/assets/images/statzo-logo.svg" alt="Logo" />
                    </a>
                </li>
                <li className="nav-item sidebar-link">
                    <Link className={`nav-link ${isActive('/admin-dashboard')}`} to="/admin-dashboard">Dashboard</Link>
                </li>
                <li className="nav-item sidebar-link">
                    <Link className={`nav-link ${isActive('/admin-dashboard/player-management')}`} to="/admin-dashboard/player-management">Player Management</Link>
                </li>
                <li className="nav-item sidebar-link">
                    <Link className={`nav-link ${isActive('/admin-dashboard/agent-management')}`} to="/admin-dashboard/agent-management">Agent Management</Link>
                </li>
                <li className="nav-item sidebar-link">
                    <Link className={`nav-link ${isActive('/admin-dashboard/manager-management')}`} to="/admin-dashboard/manager-management">Manager Management</Link>
                </li>
                <li className="nav-item sidebar-link">
                    <Link className={`nav-link ${isActive('/admin-dashboard/supporters-management')}`} to="/admin-dashboard/supporters-management">Supporters Management</Link>
                </li>
                <li className="nav-item sidebar-link">
                    <Link className={`nav-link ${isActive('/admin-dashboard/team-management')}`} to="/admin-dashboard/team-management">Team Management</Link>
                </li >
                <li className="nav-item sidebar-link">
                    <Link className={`nav-link ${isActive('/admin-dashboard/content-management')}`} to="/admin-dashboard/content-management">Content Management</Link>
                </li>
                <li className="nav-item sidebar-link">
                    <Link className={`nav-link ${isActive('/admin-dashboard/push-notifications')}`} to="/admin-dashboard/push-notifications">Push Notification</Link>
                </li>
            </ul >
        </div >
    );
};

export default Sidebar;

import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';

import axiosInstance from '../api/axiosInstance';

const SupporterDetail = () => {
    const [supporter, setSupporter] = useState(null);
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const supporterId = queryParams.get('id');

    useEffect(() => {
        const fetchSupporter = async () => {

            try {
                const token = localStorage.getItem('authToken');

                const response = await axiosInstance.get(`${process.env.REACT_APP_SUPPORTER_DETAILS_ENDPOINT}?id=${supporterId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                setSupporter(response.data.data);

            } catch (error) {
                console.error('Failed to fetch supporter details');
            }
        };

        if (supporterId) {
            fetchSupporter();
        } else {
            console.error('No supporter ID provided');
        }
    }, [supporterId]);


    return (
        <div className='supporter-detail'>
            <div className="content-wrapper">
                <section className="main-section coach-section spacer">
                    <div className="container">
                        <div className="wrapper-card">
                            <div className="supporter-img-blk">
                                <img src={`${process.env.REACT_APP_BACKEND_URL}/public/${supporter?.supporterDetails?.profileImage}`} alt="supporter" />
                            </div>
                            <div className="row gy-5">
                                <div className="col-md-6 col-lg-6">
                                    <div className="common-sub-heading">
                                        <h2>Supporter Details</h2>
                                    </div>
                                    <div className="supporter-statistics">
                                        <div className="common-info-detail">
                                            <h2 className="h4">Name</h2>
                                            <p className="mb-0">{supporter?.supporterDetails?.name}</p>
                                        </div>
                                        {/* <div className="common-info-detail">
                                            <h2 className="h4">Last name</h2>
                                            <p className="mb-0">Golding</p>
                                        </div> */}
                                        <div className="common-info-detail">
                                            <h2 className="h4">Email address</h2>
                                            <p className="mb-0">{supporter?.supporterDetails?.email}</p>
                                        </div>
                                        <div className="common-info-detail">
                                            <h2 className="h4">Phone number</h2>
                                            <p className="mb-0">{supporter?.supporterDetails?.phoneNumber}</p>
                                        </div>
                                        <div className="common-info-detail">
                                            <h2 className="h4">Status</h2>
                                            <p className="mb-0">{supporter?.supporterDetails?.status}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <div className="common-sub-heading">
                                        <h2>Added By</h2>
                                    </div>
                                    <div className="supporter-statistics">
                                        <div className="common-info-detail">
                                            <h2 className="h4">Name</h2>
                                            <p className="mb-0">{supporter?.addedBy?.name}</p>
                                        </div>
                                        {/* <div className="common-info-detail">
                                            <h2 className="h4">Last name</h2>
                                            <p className="mb-0">Golding</p>
                                        </div> */}
                                        <div className="common-info-detail">
                                            <h2 className="h4">Country</h2>
                                            <p className="mb-0">{supporter?.addedBy?.country}</p>
                                        </div>
                                        <div className="common-info-detail">
                                            <h2 className="h4">Email address</h2>
                                            <p className="mb-0">{supporter?.addedBy?.email}</p>
                                        </div>
                                        <div className="common-info-detail">
                                            <h2 className="h4">Gender</h2>
                                            <p className="mb-0">{supporter?.addedBy?.gender}</p>
                                        </div>
                                        <div className="common-info-detail">
                                            <h2 className="h4">Phone number</h2>
                                            <p className="mb-0">{supporter?.addedBy?.phoneNumber}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

    )
}

export default SupporterDetail
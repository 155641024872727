
import React from 'react';

const SearchInput = ({ searchQuery, handleSearchChange }) => {
    return (
        <div className="form-block p-relative">
            <input
                type="search"
                className="form-control"
                id="search"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchChange}
            />
            <span className="search-icon" />
        </div>
    );
};

export default SearchInput;

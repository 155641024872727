import React, { useState, useEffect } from 'react';
import Pagination from '../common/Pagination';
import { Link } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';
import { useProfileNameContext } from '../context/ProfileNameContext';
import FilterForm from '../common/FilterForm';

const TeamManagement = () => {
    const [teams, setTeams] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [teamToDelete, setTeamToDelete] = useState(null);
    const { setName } = useProfileNameContext();
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalPages, setTotalPages] = useState(1);
    const [isToggle, setToggle] = useState(false);

    useEffect(() => {
        const fetchTeams = async () => {
            try {
                const token = localStorage.getItem('authToken');

                const url = new URL(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_ALL_MANAGEMENT_ENDPOINT}`);
                url.searchParams.set('role', 'Team');
                if (searchQuery) url.searchParams.set('search', searchQuery);
                url.searchParams.set('page', currentPage);
                url.searchParams.set('limit', rowsPerPage);

                const response = await axiosInstance.get(url.toString(), {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                const fetchedTeams = response.data.data.list || [];

                setTeams(fetchedTeams);

                setTotalPages(response.data.data.totalPages ? response.data.data.totalPages : 1);

                setCurrentPage(response.data.data.currentPage);


            } catch (error) {
                console.error('Error fetching teams:', error);
            }
        };

        fetchTeams();
    }, [currentPage, rowsPerPage, searchQuery, isToggle]);

    const handleSearchChange = (e) => {

        setSearchQuery(e.target.value);

    };

    const handleDeleteConfirm = async (t) => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await axiosInstance({
                method: 'DELETE',
                url: `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_USER_ENDPOINT}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: {
                    id: t._id
                }
            });

            if (response.status === 200) {
                iziToast.success({ title: 'Success', message: 'Team successfully deleted!' });
                setTeams(teams.filter(team => team._id !== t._id));
                setDisplayedTeams(displayedTeams.filter(team => team._id !== t._id));
            } else {
                throw new Error('Failed to delete team');
            }
        } catch (error) {
            iziToast.error({ title: 'Error', message: 'Failed to delete team' });
        }
        setTeamToDelete(null);
    };

    const handleDeleteClick = (team) => {
        iziToast.show({

            title: 'Delete Confirmation',
            message: 'Are you sure you want to delete this team?',
            buttons: [
                ['<button>Yes</button>', function (instance, toast) {
                    handleDeleteConfirm(team);
                    instance.hide({ transitionOut: 'fadeOut' }, toast);
                }],
                ['<button>No</button>', function (instance, toast) {
                    instance.hide({ transitionOut: 'fadeOut' }, toast);
                    setTeamToDelete(null);
                }]
            ],
            position: 'center',
        });

        setTeamToDelete(team);
    };

    const toggleStatus = async (team) => {
        try {
            const token = localStorage.getItem('authToken');
            const newStatus = manager.status;
            const response = await axiosInstance({
                method: 'POST',
                url: `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_STATUS_ENDPOINT}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: {
                    id: team._id,
                    status: newStatus
                }
            });

            if (response.status === 200) {

                setPlayers((prevTeams) =>
                    prevTeams.map((p) =>
                        p._id === manager._id ? { ...p, status: newStatus } : p
                    )
                );

                setToggle((prev) => !prev)
                iziToast.success({ title: 'Success', message: 'Team status updated!' });
            } else {
                throw new Error('Failed to update status');
            }
        } catch (error) {
            iziToast.error({ title: 'Error', message: 'Failed to update status' });
        }
    };

    const handleTeamDetailClick = (team) => {
        setName(team.username);
    };
    const handleClear = () => {
        setSearchQuery(''); setDateFrom('');
        setDateTo('');
    }
    return (
        <div>
            <section className="main-section spacer">
                <div className="container">
                    <div className="wrapper-card">
                        <FilterForm
                            searchQuery={searchQuery}
                            handleSearchChange={handleSearchChange}
                            handleClear={handleClear}
                        />
                    </div>
                    <div className="wrapper-card">
                        <div className="row gy-3">
                            <div className="col-xl-12">
                                <div className="sub-heading flex-box">
                                    <h2>Team Listing</h2>
                                </div>
                                <div className="table-blk">
                                    {teams.length === 0 && searchQuery.trim() !== '' ? (
                                        <div className="no-data">No data available</div>
                                    ) : (
                                        <table id="myTable" className="display border-0">
                                            <thead>
                                                <tr>

                                                    <th>Team Name</th>
                                                    <th>Total Number of Players</th>
                                                    <th>Phone Number</th>
                                                    <th>Created By</th>
                                                    <th>Actions</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {teams.length > 0 ? (
                                                    teams.map(team => (
                                                        <tr key={team._id}>
                                                            <td>{team.name}</td>
                                                            <td>{team.squad}</td>
                                                            <td>{team.userDetail.phoneNumber}</td>
                                                            <td>{team.userDetail.username}</td>
                                                            <td>
                                                                <span className={`table-status ${team.status}`}>{team.status === 'Active' ? 'Active' : 'Suspended'}</span>
                                                            </td>
                                                            <td>
                                                                <span className="table-action">
                                                                    <Link to={`/admin-dashboard/team-detail?id=${team._id}`} onClick={handleTeamDetailClick(team)} className="table-eye">
                                                                        <svg width="26" height="17" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13 0.59375C7.09091 0.59375 2.04455 4.28688 0 9.5C2.04455 14.7131 7.09091 18.4062 13 18.4062C18.9091 18.4062 23.9555 14.7131 26 9.5C23.9555 4.28688 18.9091 0.59375 13 0.59375ZM13 15.4375C9.73818 15.4375 7.09091 12.7775 7.09091 9.5C7.09091 6.2225 9.73818 3.5625 13 3.5625C16.2618 3.5625 18.9091 6.2225 18.9091 9.5C18.9091 12.7775 16.2618 15.4375 13 15.4375ZM13 5.9375C11.0382 5.9375 9.45455 7.52875 9.45455 9.5C9.45455 11.4712 11.0382 13.0625 13 13.0625C14.9618 13.0625 16.5455 11.4712 16.5455 9.5C16.5455 7.52875 14.9618 5.9375 13 5.9375Z" fill="#000000" />
                                                                        </svg>
                                                                    </Link>
                                                                    <span className="table-check">
                                                                        <input
                                                                            type="checkbox"
                                                                            id={`toggleEye${team._id}`}
                                                                            checked={team.status === 'Active'}
                                                                            onChange={() => toggleStatus(team)}
                                                                        />
                                                                        <label htmlFor={`toggleEye${team._id}`} />
                                                                    </span>
                                                                    <button className="table-trash" onClick={() => handleDeleteClick(team)}>
                                                                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <g clip-path="url(#clip0_921_6579)">
                                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9162 17.0001H4.08406C3.15161 17.0001 2.39209 16.2406 2.39209 15.3082V3.5293H14.6066V15.3082C14.608 16.2406 13.8485 17.0001 12.9161 17.0001H12.9162ZM5.65441 14.6994C5.27948 14.6994 4.97378 14.3937 4.97378 14.0188V5.71523C4.97378 5.3403 5.27948 5.0346 5.65441 5.0346C6.03074 5.0346 6.33504 5.3403 6.33504 5.71523V14.0174C6.33644 14.3937 6.03064 14.6994 5.65441 14.6994ZM8.50016 14.6994C8.12533 14.6994 7.81953 14.3937 7.81953 14.0188V5.71523C7.81953 5.3403 8.12523 5.0346 8.50016 5.0346C8.87649 5.0346 9.18079 5.3403 9.18079 5.71523V14.0174C9.18079 14.3937 8.87639 14.6994 8.50016 14.6994ZM11.3459 14.6994C10.971 14.6994 10.6653 14.3937 10.6653 14.0188V5.71523C10.6653 5.3403 10.971 5.0346 11.3459 5.0346C11.7208 5.0346 12.0265 5.3403 12.0265 5.71523V14.0174C12.0265 14.3937 11.7207 14.6994 11.3458 14.6994H11.3459Z" fill="#000000"></path>
                                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.8724 3.13052H1.12744C1.01678 3.13052 0.928223 3.04197 0.928223 2.9313V1.73738C0.928223 1.53258 1.09567 1.36523 1.30036 1.36523H15.6995C15.9043 1.36523 16.0716 1.53268 16.0716 1.73738V2.9313C16.0716 3.04197 15.9816 3.13052 15.8724 3.13052Z" fill="#000000"></path>
                                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4296 1.76528H6.56983C6.45916 1.76528 6.37061 1.67673 6.37061 1.56606V0.372142C6.37061 0.167344 6.53805 0 6.74275 0H10.2581C10.4629 0 10.6302 0.167444 10.6302 0.372142V1.56606C10.6288 1.67534 10.5403 1.76528 10.4296 1.76528Z" fill="#000000"></path>
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_921_6579">
                                                                                    <rect width="17" height="17" fill="white"></rect>
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>
                                                                    </button>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="7" style={{ padding: 0 }}>
                                                            <div className="no-data">No data available</div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    )}
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onPageChange={setCurrentPage}
                                        setRowsPerPage={setRowsPerPage}
                                        currentRow={rowsPerPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default TeamManagement
import React, { useState } from 'react';
import axiosInstance from '../api/axiosInstance';
import { showErrorToast, showSuccessToast } from '../api/ToastNotification';

const PushNotifications = () => {
    const [role, setRole] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('authToken')
        try {
            // Make the API call using axios instance
            const response = await axiosInstance({
                method: 'POST',
                url: `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_ADMIN_SEND_NOTIFICATIONS_DETAILS_ENDPOINT}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: {
                    role,
                    title,
                    description,
                }
            });


            console.log('Notification Successfully Sent:', response.data);
            showSuccessToast('Notification Successfully Sent')
            // Clear the form
            setRole('');
            setTitle('');
            setDescription('');
        } catch (error) {
            console.error('Error:', error);
            showErrorToast('Error in sending Notification')
        }
    };


    return (
        <div>
            <section className="main-section push-notification spacer">
                <div className="container">
                    <div className="wrapper-card">
                        <div className="row justify-content-center">
                            <div className="col-xl-6">
                                <form>
                                    <div className="push-main-wrapper">
                                        <div className="row gy-4">
                                            <div className="col-lg-12">
                                                <div className="sub-heading mt-0 mb-3">
                                                    <h2 className="h4 mb-0">Select user</h2>
                                                </div>
                                                <div className="form-block p-relative">
                                                    <select className="form-select" value={role}
                                                        onChange={(e) => setRole(e.target.value)}>
                                                        <option value="">Select user</option>
                                                        <option value="Manager">Manager</option>
                                                        <option value="Player">Player</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="sub-heading mt-0 mb-3">
                                                    <h2 className="h4 mb-0">Title</h2>
                                                </div>
                                                <div className="form-block p-relative">
                                                    <input type="text" className="form-control" value={title}
                                                        onChange={(e) => setTitle(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="sub-heading mt-0 mb-3">
                                                    <h2 className="h4 mb-0">Enter Description</h2>
                                                </div>
                                                <div className="form-block p-relative">
                                                    <textarea className="form-control" value={description}
                                                        onChange={(e) => setDescription(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-xxl-12">
                                                <div className="push-notification-btn d-flex justify-content-center">
                                                    <button type="button" className="btn primary-btn" onClick={handleSubmit}>Send</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>

    )
}

export default PushNotifications